import overlayIllustration from 'assets/illustrations/overlay.svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 4rem;
  // background-image: url(${overlayIllustration});
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  @media (max-width: 960px) {
    background-image: none;
    
  }
`;

export const IntroWrapper = styled.div`
  padding: 2.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  .color-red {
    color: red;
  }
  .color-orange {
    color: #ffd777;
  }

  .color-faded {
    color: #8d8d8d;
  }
  .color-strong {
    color: #767676e8;
  }
  .color-blue {
    color: #0095eb;
  }

  .short-desc {
    font-size: 15pt;
    line-height: 1.8;
  }
  code {
    font-size: 17pt;
    line-height: 2;
    background: none;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 36pt;
    color: ${({ theme }) => (theme === 'light' ? '#2E3440' : '#ECEFF4')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 30pt;
    }
  }

  .big-font {
    font-size: 32pt;
  }

  h4 {
    margin-bottom: 2.5rem;
    font-size: 32pt;
    font-weight: normal;
    line-height: 1.5;
    color: ${({ theme }) => (theme === 'light' ? '#2E3440' : '#ECEFF4')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 26pt;
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    .avatar {
      width: 50%;
    }
  }

  img.avatar {
    width: 80%;
    text-align: right;
    align-content: right;
    margin: 0px auto;
    padding: 20px;
  }
`;
