import dev from 'assets/illustrations/blogging.json';
import { Container } from 'components/common';
import { ThemeContext } from 'providers/ThemeProvider';
import React, { useContext } from 'react';
import Lottie from 'react-lottie';
import { Details, SkillsWrapper, Thumbnail, Wrapper } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: dev,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <Lottie options={defaultOptions} width="90%" />
        </Thumbnail>
        <Details theme={theme}>
          <h1>About me</h1>

          <p>I am a software engineer based in Kathmandu, Nepal. </p>

          <p>
            I enjoy creating things that live on the internet, whether that be websites, applications, or anything in
            between and recently I have started to learn and build ai-based applications.
          </p>
          <p>
            I'm a self-taught <b>T</b> shaped individual who enjoys the challenges of a busy demanding environment and
            enjoys working on a team or individually.
          </p>
          <p>Here are a few technologies I've been working with recently:</p>
          <ul>
            <li>Python</li>
            <li>Django</li>
            <li>Kubernetes</li>
            <li>Docker</li>
            <li>GCP</li>
            <li>AWS</li>

            <li>JavaScript (ES6+)</li>
            <li>TypeScript</li>

            <li>React</li>
            <li>NextJS</li>

            <li>GraphQL</li>
          </ul>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
