import styled from 'styled-components';

export const Wrapper = styled.div`
  .content {
    margin: auto;
    padding: auto;
    text-align: center;
    width: 50%;
    margin-top: 50px;
  }

  @media (max-width: 960px) {
    .content {
      width: 100% !important;
    }
  }

  .mt-5 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  p {
    line-height: 2;
  }
  a {
    cursor: pointer;
    border-radius: 3px;
    padding: 0.7rem 2.5rem;
    border: none;
    // width: 100%;
    text-align: center;
    -webkit-appearance: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #d8dee9;
    background: #2e3440;

    &:focus {
      outline: none;
    }
  }
`;
