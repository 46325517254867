import ballot from '../../../assets/works/ai/ballot.png';
import gan from '../../../assets/works/ai/gan.png';
import ml_emotion from '../../../assets/works/ai/ml_emotion.png';
import sentiment_clf from '../../../assets/works/ai/sentiment_clf.png';

function importAll(r) {
  return r.keys().map(r);
}

const xceltoken = importAll(require.context('../../../assets/works/xceltoken', false, /\.(png|jpe?g|svg)$/));

const namastenepal = importAll(require.context('../../../assets/works/namastenepal', false, /\.(png|jpe?g|svg)$/));
const flyeasy = importAll(require.context('../../../assets/works/flyeasy', false, /\.(png|jpe?g|svg)$/));

const foodmyway = importAll(require.context('../../../assets/works/foodmyway', false, /\.(png|jpe?g|svg)$/));

const emandi = importAll(require.context('../../../assets/works/emandi', false, /\.(png|jpe?g|svg)$/));

const trek = importAll(require.context('../../../assets/works/trek', false, /\.(png|jpe?g|svg)$/));

const palika = importAll(require.context('../../../assets/works/palika', false, /\.(png|jpe?g|svg)$/));

export const se_works = {
  xcel: {
    link: 'https://www.xceltoken.com/',
    title: 'XcelToken Exchange(Frontend)',
    descriptions: `  XcelToken Exchange is the Easiest Way to Buy and Sell Digital
    Currency. It is a Secure, Trusted and Transparent Global Crypto
    Trading Platform.`,
    images: xceltoken,
    reverse: false,
    tech: ['ReactJS', 'Redux', 'Trading View Charts'],
  },
  foodmyway: {
    link: 'https://food-myway.herokuapp.com/',
    title: 'Foodmyway',
    descriptions: `Foodmyway is an Automated Meal planning app that lets you plan
    your daily meals with the right amount of ingredients you want
    with suggestions.`,
    images: foodmyway,
    reverse: true,
    tech: ['Python', 'Django', 'DRF', 'ReactJS', 'Redux', 'GraphQL'],
  },
  flyeasy: {
    link: 'https://fly-easy.herokuapp.com/',
    title: 'Flyeasy',
    descriptions: `Fly easy is a small Flight booking system where you can have a
    simulated environment of booking flight seats and reserving
    flight tickets.`,
    images: flyeasy,
    reverse: false,
    tech: ['NodeJS', 'ReactJS', 'Redux', 'MongoDB'],
  },
  eandi: {
    link: 'https://e-mandi.herokuapp.com/',
    title: 'e-Mandi',
    descriptions: `This project aims to provide a farmer with the real price value they worth for their agricultural products by developing an online platform, where they can add their products and other buyers who demand that product can bid with a real-time transaction.`,
    images: emandi,
    reverse: true,
    tech: ['Python', 'Django', 'DRF', 'ReactJS', 'Redux', 'WebSocket', 'Django Channels'],
  },

  trek_bot: {
    link: 'https://m.me/435070913709635',
    title: 'FB Messenger Bot: Trek bot',
    descriptions: `Service built on Facebook Messenger Platform for Travel agencies
    to automate inquiries and to provide news, notices and book the
    new travel packages.`,
    images: trek,
    reverse: false,
    tech: ['Python', 'FB Messenger'],
  },

  gaupalika_bot: {
    link: 'https://m.me/helamburm',
    title: 'FB Messenger Bot: Gaupalika',
    descriptions: `
    Service built on Facebook Messenger Platform for Village
                Development Committee to automate Inquiries and to provide news,
                notices about the village.
    `,
    images: palika,
    reverse: true,
    tech: ['Python', 'FB Messenger'],
  },
};

export const ai_works = {
  namastenepal: {
    link: '#',
    title: 'NamasteNepal(Beta)',
    descriptions: `NamastNepal is an ai-enabled Social Networking Platform for all types of users. They can Register/Login to their accounts with a
    phone number or the email verification process and subscribe to different communities of their choice. It has several ai-enabled modules such as NSFW filter, auto image captioning, face detection and tagging(coming soon)`,
    images: namastenepal,
    reverse: false,
    tech: ['Python', 'Django', 'DRF', 'PyTorch', 'React', 'Redux', 'Websocket', 'Django Channels'],
  },

  se_clf: {
    link: 'https://github.com/Anyesh/sentiment-rnn.git',
    title: 'Sentiment Classifier',
    descriptions: `Sentiment Classification from the text.
    The models use bi-directional GRU with pre-trained weights of
    BERT in the embedding layer. It was trained on Tesla V100 GPU on 1.5M tweets data labeled as positive and negative with 85% validation accuracy. For the web application, Flask is used.`,
    images: [sentiment_clf],
    reverse: true,
    tech: ['Python', 'PyTorch', 'Flask'],
  },

  art_gan: {
    link: 'https://github.com/Anyesh/art_gan',
    title: 'Modern Art GAN',
    descriptions: `Modern Art GAN is a deep convolution generative adversarial network(DCGAN) model trained on a cubism style art from the BAM dataset.
    It was trained on Tesla V100 GPU to generate similar modern art.
    `,
    images: [gan],
    reverse: false,
    tech: ['Python', 'TF', 'Keras'],
  },

  ml_se_clf: {
    link: 'https://github.com/Anyesh/fusemachines_training',
    title: 'Emotion Detection and Recognition from Text data',
    descriptions: `This app was built as a mini project at Fusemachines training. It uses the NaiveBayes model for Emotion detection and recognition from text data. 
    Flask is used for the web app, API, and Docker for containerizing the app.
    `,
    images: [ml_emotion],
    reverse: true,
    tech: ['Python', 'Keras', 'Sklearn', 'NLTK', 'Flask', 'Docker'],
  },

  ballot_cv: {
    link: 'https://github.com/Anyesh/ballot_paper_classification',
    title: 'Ballot Paper Classification - Computer Vision Project',
    descriptions: `This app was built as a mini project at Fusemachines training. It uses pre-trained ResNet50 architecture for ballot paper classification. Flask is used for the web app and API.    `,
    images: [ballot],
    reverse: false,
    tech: ['Python', 'Keras', 'OpenCV'],
  },
};
