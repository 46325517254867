import detailsIllustration from 'assets/illustrations/details.svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  // background-image: url(${detailsIllustration});
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
  margin-bottom: 40px;
`;

export const SkillsWrapper = styled.div`
  padding: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column-reverse;
    padding: 4rem 0;
  }
`;

export const Details = styled.div`
  flex: 1;

  padding-left: 2rem;

  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 26pt;
    color: ${({ theme }) => (theme === 'dark' ? '#ECEFF4' : '#2E3440')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
    // &:after {
    //   content: "";
    //   display: inline-block;
    //   width: 70%;

    //   margin-right: -100%;
    //   border-bottom: 1px solid #707070;
    //   margin-bottom: 10px;
    //   margin-left: 10px;
    // }
  }

  ul {
    list-style-type: square;
    li {
      float: left;
      margin-right: 25px;
    }
  }

  p {
    font-size: 14pt;

    font-weight: normal;
    line-height: 1.6;
    color: ${({ theme }) => (theme === 'dark' ? '#ECEFF4' : '#2E3440')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;
  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;
