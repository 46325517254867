import React from "react";
import { Layout, SEO } from "components/common";
import { Intro, Skills, Projects, Blogs, Contact } from "components/landing";
import { Works } from "components/landing/Works";

export default () => (
  <Layout>
    <SEO />
    <Intro />
    <Skills />
    <Works />
    <Projects />
    <Blogs />
    <Contact />
  </Layout>
);
