import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem 0;

  .badge__shine {
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding: 2px 4px;
    border-radius: 5px;
    color: #eceff4;
    text-decoration: none;
    text-align: center;
    font-size: 15px;
    margin-left: 4px;
  }

  .badge__shine:nth-child(1) {
    background: #a3be8c;
  }
  // h2{
  //   &:after{
  //     content:'';
  //     display:inline-block;
  //     width: 85%;

  //     margin-right:-100%;
  //     border-bottom:1px solid #2E3440;
  //     margin-bottom: 10px;
  //     margin-left: 10px;
  //   }
  // }

  .badge__shine:after {
    animation: shine 1s linear infinite;
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    transform: rotate(30deg);
  }

  // .badge__shine:active:after {
  //   opacity: 0;
  // }

  @keyframes shine {
    0% {
      opacity: 1;
      top: -110%;
      left: -210%;
      background: rgba(255, 255, 255, 0.13);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.5) 92%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    100% {
      opacity: 1;
      top: -30%;
      left: 300%;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 8fr;
  gap: 1.2rem 1.2rem;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);

  h4 {
    color: ${({ theme }) => (theme === 'light' ? '#2E3440' : '#ECEFF4')};
  }

  p {
    color: ${({ theme }) => (theme === 'light' ? '#2E3440' : '#ECEFF4')};
  }

  transition: all 0.3s ease-in;

  &&:hover {
    // transition: all 0.9s ease-in;
    opacity: 0.9;
    transform: scale(1.05);
  }
`;

export const Content = styled.div`
  padding: 1rem 0;

  .thumbnail {
    display: block;
    overflow: hidden;
    height: 200px;
  }
  .thumbnail img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const Tags = styled.div`
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;

  li {
    float: left;
  }

  .tag {
    background: #eee;
    border-radius: 3px 0 0 3px;
    color: #999;
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 20px 0 23px;
    position: relative;
    margin: 0 10px 10px 0;
    text-decoration: none;
    -webkit-transition: color 0.2s;
  }

  .tag::before {
    background: #eceff4;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px;
  }

  .tag::after {
    background: #eceff4;
    border-bottom: 13px solid transparent;
    border-left: 10px solid #eee;
    border-top: 13px solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
  }

  .tag:hover {
    background-color: #a3be8c;
    color: white;
  }

  .tag:hover::after {
    border-left-color: #a3be8c;
  }
`;

export const Stats = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;

    &:first-child {
      margin-right: 0.5rem;
    }

    img {
      margin: 0;
    }

    svg path {
      fill: ${({ theme }) => (theme === 'light' ? '#4C566A' : '#E5E9F0')};
    }

    span {
      color: ${({ theme }) => (theme === 'light' ? '#4C566A' : '#E5E9F0')};
      margin-left: 0.5rem;
    }
  }
`;
