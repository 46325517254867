import styled from 'styled-components';
import playIcon from '../../../assets/icons/play.png';

export const Wrapper = styled.div`
  padding: 2rem 0;

  // h1 {
  //   &:after {
  //     content: "";
  //     display: inline-block;
  //     width: 75%;

  //     margin-right: -100%;
  //     border-bottom: 1px solid #707070;
  //     margin-bottom: 10px;
  //     margin-left: 10px;
  //   }
  // }

  // hr {
  //   margin-top: 50px;
  // }

  // @media (max-width: 960px) {
  //   hr {
  //     margin-top: 0px;
  //   }
  // }
`;

export const Tab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10px;

  .col {
    width: 100%;
    text-align: center;

    // border-radius: 3px;
    &:first-child {
      border-right: 1px solid #eceff4;
    }

    button {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      cursor: pointer !important;
      background: none;
      border: none;
      padding: 15px;
      border-bottom: 5px solid #eceff4;
      color: ${({ theme }) => (theme === 'dark' ? '#ECEFF4' : '#2E3440')};
      transition: all 0.5s;

      &:hover {
        color: #a3be8c;
      }
    }
    button.active {
      border-bottom: 5px solid #a3be8c;
    }
  }
`;

export const WorksWrapper = styled.div`
  padding: 2rem 0;
  display: flex;
  margin-top: 10px;
  padding-bottom: 50px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 960px) {
    flex-direction: ${({ reverse }) => (reverse === true ? 'column-reverse' : 'column')};
  }
  }
`;

export const Details = styled.div`
  flex: 1;
  a {
    color: ${({ theme }) => (theme === 'dark' ? '#ECEFF4' : '#2E3440')};
    transition: all 0.6s;
    letter-spacing: 0.1em;

    &:hover {
      color: #bf616a;
    }
  }

  padding-left: 2rem;
  margin-top: 2rem;

  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
  }

  code {
    margin-right: 20px;
    font-size: 12pt;
  }
  margin-bottom: 20px;
  h1 {
    margin-bottom: 2rem;

    color: ${({ theme }) => (theme === 'dark' ? '#ECEFF4' : '#2E3440')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }

  ul {
    list-style-type: square;
    li {
      float: left;
      margin-right: 25px;
    }
  }

  p {
    font-size: 14pt;

    font-weight: normal;
    line-height: 1.6;
    color: ${({ theme }) => (theme === 'dark' ? '#c7c7c7' : '#707070')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;
  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  background: ${({ theme }) => (theme === 'dark' ? 'black' : 'white')};

  img {
    width: 100%;
    text-align: center;
    margin: 0px auto;
    object-fit: contain !important;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  .react-player__play-icon {
    background: url(${playIcon});
    color: #bf616a;
  }
`;
