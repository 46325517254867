import { Card, Container } from 'components/common';
import { ThemeContext } from 'providers/ThemeProvider';
import React, { useContext, useEffect, useState } from 'react';
import { Content, Grid, Item, Wrapper } from './styles';

export const Blogs = () => {
  const { theme } = useContext(ThemeContext);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    const response = await fetch(
      'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@sthacruz?count=9'
    );
    const data = await response.json();
    setBlogs(data);
  };

  return (
    <Wrapper as={Container} id="medium">
      <h2>
        Medium Stories <span className="badge__shine">NEW</span>
      </h2>
      <p>{blogs?.feed?.title}</p>

      <Grid>
        {blogs?.items?.map(item => (
          <Item key={item.guid} as="a" href={item.link} target="_blank" rel="noopener noreferrer" theme={theme}>
            <Card theme={theme}>
              <Content>
                <div className="thumbnail">
                  <img src={item.thumbnail} alt="" className="img-fluid" />
                </div>
              </Content>
              <h4 style={{ lineHeight: '1.5' }}>{item.title}</h4>
              <p>{new Date(item.pubDate).toDateString()}</p>
            </Card>
            <div className="card-blur"></div>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  );
};
