import dev from 'assets/illustrations/anyesh_dev.svg';
import { Container } from 'components/common';
import { Header } from 'components/theme';
import { ThemeContext } from 'providers/ThemeProvider';
import React, { useContext } from 'react';
import { Details, IntroWrapper, Thumbnail, Wrapper } from './styles';

export const Intro = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          <code>Hi, my name is</code>
          <h4>
            <span> Anish Shrestha. </span> <br />
            <span className="color-faded">
              I build <code className="big-font">AI-based</code> things for web.
            </span>
          </h4>
          <p className="short-desc">
            I'm a software engineer specializing in building ai-based solutions, web applications, and everything in
            between.
          </p>
        </Details>
        <Thumbnail>
          <img src={dev} alt="Anish Shrestha" className="avatar" />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
