import { ThemeContext } from 'providers/ThemeProvider';
import React, { useContext, useState } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { Container } from '../../common/Container';
import { ai_works, se_works } from './data';
import { Details, Tab, Thumbnail, WorksWrapper, Wrapper } from './styles';

export const Works = () => {
  const { theme } = useContext(ThemeContext);
  const [tab, setTab] = useState('SE');

  const handleActiveTab = name => {
    setTab(name);
  };

  const renderWork = () => {
    switch (tab) {
      case 'SE':
        return Object.keys(se_works).map(key => (
          <WorksWrapper key={key} reverse={se_works[key].reverse}>
            {se_works[key].reverse === false ? (
              <Thumbnail theme={theme}>
                <AwesomeSlider
                  play
                  // cancelOnInteraction={false}
                  interval={1000}
                  style={{ height: '200' }}
                >
                  {se_works[key].images?.map((img, idx) => (
                    <div key={idx}>
                      <img src={img} alt="" />
                    </div>
                  ))}
                </AwesomeSlider>
              </Thumbnail>
            ) : (
              ''
            )}
            <Details theme={theme}>
              <h3>
                <a href={se_works[key].link} target="_blank" rel="noopener noreferrer">
                  {se_works[key].title}
                </a>
              </h3>

              <p>{se_works[key].descriptions}</p>
              {se_works[key].tech.map((t, i) => (
                <code key={i}>{t}</code>
              ))}
            </Details>
            {se_works[key].reverse ? (
              <Thumbnail theme={theme}>
                <AwesomeSlider
                  play
                  // cancelOnInteraction={false}
                  interval={1000}
                >
                  {se_works[key].images?.map((img, idx) => (
                    <div key={idx}>
                      <img src={img} alt="" />
                    </div>
                  ))}
                </AwesomeSlider>
              </Thumbnail>
            ) : (
              ''
            )}
          </WorksWrapper>
        ));

      case 'ML':
        return Object.keys(ai_works).map(key => (
          <WorksWrapper key={key} reverse={ai_works[key].reverse}>
            {ai_works[key].reverse === false ? (
              <Thumbnail>
                <AwesomeSlider>
                  {ai_works[key].images?.map((img, idx) => (
                    <div key={idx}>
                      <img src={img} alt="" />
                    </div>
                  ))}
                </AwesomeSlider>
              </Thumbnail>
            ) : (
              ''
            )}
            <Details theme={theme}>
              <h3>
                <a href={ai_works[key].link} target="_blank" rel="noopener noreferrer">
                  {ai_works[key].title}
                </a>
              </h3>

              <p>{ai_works[key].descriptions}</p>
              {ai_works[key].tech.map((t, i) => (
                <code key={i}>{t}</code>
              ))}
            </Details>
            {ai_works[key].reverse ? (
              <Thumbnail>
                <AwesomeSlider>
                  {ai_works[key].images?.map((img, idx) => (
                    <div key={idx}>
                      <img src={img} alt="" />
                    </div>
                  ))}
                </AwesomeSlider>
              </Thumbnail>
            ) : (
              ''
            )}
          </WorksWrapper>
        ));

      default:
    }
  };

  return (
    <Wrapper id="works" as={Container}>
      <h1>Some Things I've Built</h1>
      <Tab theme={theme}>
        <div className="col">
          <button className={tab === 'SE' ? 'active' : ''} onClick={() => handleActiveTab('SE')}>
            <span className="text-truncate">Web Applications</span>
          </button>
        </div>
        <div className="col">
          <button onClick={() => handleActiveTab('ML')} className={tab === 'ML' ? 'active' : ''}>
            <span className="text-truncate">Artificial Intelligence</span>
          </button>
        </div>
      </Tab>

      {renderWork()}
    </Wrapper>
  );
};
