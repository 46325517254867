import { Card, Container } from 'components/common';
import { graphql, useStaticQuery } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import React, { useContext } from 'react';
import { Content, Grid, Item, Stats, Wrapper } from '../Blogs/styles';

export const Projects = () => {
  const { theme } = useContext(ThemeContext);
  const {
    github: {
      viewer: {
        repositories: { edges },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        github {
          viewer {
            repositories(first: 12, orderBy: { field: UPDATED_AT, direction: DESC }) {
              edges {
                node {
                  id
                  name
                  url
                  description
                  isPrivate
                  stargazers {
                    totalCount
                  }
                  forkCount
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <Wrapper as={Container}>
      <h2>
        GitHub Projects <span className="badge__shine">NEW</span>
      </h2>

      <Grid>
        {edges.map(({ node }) => (
          <Item key={node.id} as="a" href={node.url} target="_blank" rel="noopener noreferrer" theme={theme}>
            <Card theme={theme}>
              <Content>
                <h4>{node.name}</h4>
                <p>{node.description}</p>
              </Content>
              <Stats theme={theme}>
                <div>
                  {node.isPrivate ? (
                    <span className={theme === 'light' ? 'color-red' : 'color-blue'}>🔒 Private</span>
                  ) : (
                    <span>🌏 Public</span>
                  )}
                </div>
              </Stats>
            </Card>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  );
};
