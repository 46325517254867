import { Container } from 'components/common';
import React from 'react';
import { Wrapper } from './styles';

export const Contact = () => (
  <Wrapper as={Container} id="contact">
    <div className="content ">
      <hr />

      <div className="mt-5">
        <h1>Get In Touch</h1>

        <p>
          Although I'm not currently looking for any new opportunities, my inbox is always open. Whether you have a
          question or just want to say hi, I'll try my best to get back to you!
        </p>

        <a href="mailto:sthacruz@gmail.com">Say Hello</a>
      </div>
    </div>
  </Wrapper>
);
